import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/durchfall-bei-kindern"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
            IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
            </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Diagnose Reizdarm</a>
                    </li>
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337 active-trail active">
                      <a
                        href="/durchfall-bei-kindern/"
                        className="active-trail active"
                      >
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-bei-kindern"
                  className="block block-menu"
                >
                  <h2>Durchfall bei Kindern</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first last leaf active-trail">
                        <a
                          href="/durchfall-bei-kindern/"
                          title
                          className="active-trail active"
                        >
                          Durchfall bei Kindern
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-11" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/120228_imodium_anlan_07.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4
                        style={{
                          "font-size": "30px",
                          "line-height": "26px",
                          margin: "20px 0pt 10px",
                          "font-family": "'Open Sans',sans-serif"
                        }}
                      >
                        TIPPS BEI
                        <br />
                        DURCHFALL
                      </h4>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/teaser_durchfallmythen.jpg"
                          style={{
                            width: "174px",
                            height: "106px"
                          }}
                        />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Beim Thema Durchfall-
                        <br />
                        behandlung gibt es immer noch viele Unsicherheiten.
                        Lesen Sie hier einige Tipps.
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a
                            className="georgia arrow_01"
                            href="/"
                          >
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div id="block-block-20" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/120228_imodium_anlan_07.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4 className="sideBlock">
                        HABEN SIE
                        <br />
                        FRAGEN?
                      </h4>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "0px 0",
                          "line-height": "18px"
                        }}
                      >
                        &nbsp;
                      </div>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "0px 0",
                          "line-height": "18px"
                        }}
                      >
                        Wenden Sie sich an unsere Experten.
                      </div>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "0px 0",
                          "line-height": "18px"
                        }}
                      >
                        <p>
                          <a
                            className="georgia arrow_01"
                            href="/expertenrat/"
                            target="_self"
                          >
                            Jetzt informieren
                          </a>
                        </p>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <div
                      about="/durchfall-bei-kindern"
                      typeof="sioc:Item foaf:Document"
                      className="ds-2col-stacked node node-simple-page node-promoted view-mode-full clearfix"
                    >
                      <div className="group-header">
                        <h1>Durchfall bei Kindern </h1>
                        <div className="headimg border">
                          <img
                            typeof="foaf:Image"
                            src="/sites/default/files/kinder.jpg"
                            width="695"
                            height="286"
                          />{" "}
                        </div>
                        <div className="field field-name-field-body-left field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <h3>Woher kommt Durchfall bei Kindern?</h3>
                              <p>
                                In den ersten drei Lebensjahren leidet ein Kind
                                durchschnittlich ein- bis zweimal pro Jahr an
                                Durchfall. Die Hauptursache dafür sind
                                Viruserkrankungen, welche für etwa 70 Prozent
                                aller Durchfälle verantwortlich sind. Gegen die
                                besonders häufig auftretenden Rotaviren können
                                Sie Ihr Kind mittlerweile impfen lassen. Aber
                                auch Nahrungsmittelunverträglichkeiten können
                                Auslöser von Durchfällen sein.
                              </p>
                              <p>
                                Unabhängig von der Ursache des Durchfalles ist
                                bei Kindern eine frühzeitige und altersgerechte
                                Behandlung besonders wichtig. Denn bei Kindern
                                führt Durchfall noch schneller zu Austrocknung
                                als bei einem Erwachsenen. Generell ist damit
                                ein Verlust an lebensnotwendigen Elektrolyten
                                verbunden.
                              </p>
                              <h3>
                                Wie kann ich akuten Durchfall bei Kindern ab 12
                                Jahren behandeln?
                              </h3>
                              <p>
                                Zur symptomatischen Behandlung von akutem
                                Durchfall bei Kindern ab 12 Jahren erhalten Sie{" "}
                                <a href="/wie-wirkt-imodium-akut/">
                                  IMODIUM<sup>®</sup>
                                </a>{" "}
                                rezeptfrei in Ihrer Apotheke.&nbsp;In der
                                Apotheke können Sie zusätzlich fertige
                                Elektrolytmischungen speziell für Kinder
                                bekommen, mit denen der Verlust von Elektrolyten
                                ausgeglichen werden kann.
                              </p>
                              <h3>
                                Wie behandle ich akuten Durchfall bei Kindern
                                unter 12 Jahren?
                              </h3>
                              <p>
                                Für Kinder unter 12 Jahren sind Produkte mit Loperamid aufgrund ihres zu hohen Wirkstoffgehalts nicht geeignet.&nbsp;
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="field field-name-field-body-right field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <p>
                                Der Wasser- und Elektrolytausgleich mit
                                Elektrolytmischungen aus der Apotheke erfolgt am
                                besten zwischen den ,normalen‘ Mahlzeiten. Die
                                Akzeptanz dieser Mischungen ist bei Kindern oft
                                besser, wenn sie gekühlt oder mit Raumtemperatur
                                verabreicht werden. Geschmacksstoffe fördern
                                zusätzlich die Akzeptanz. Um Erbrechen zu
                                minimieren, sollten zunächst kleine Mengen mit
                                einem Teelöffel gegeben werden, wie zum Beispiel
                                ein Teelöffel alle ein bis zwei Minuten. Werden
                                diese kleinen Portionen ohne Erbrechen von Ihrem
                                Kind vertragen, können die Menge erhöht und die
                                Häufigkeit der Gaben gesteigert
                                werden.&nbsp;Mahlzeiten sollten weitestgehend im
                                normalen Rahmen erfolgen. Säuglinge können
                                zwischen den Gaben der Rehydrierungslösungen zum
                                Stillen an die Brust angelegt werden.
                              </p>
                              <p>
                                Wenn Kleinkinder unter Durchfall leiden, sollten
                                sie zunächst Nahrungsmittel mit komplexen
                                Kohlenhydraten wie Brot mit Aufstrich, Nudel-,
                                Kartoffel- oder Reisgerichte, Hafer- oder
                                Grießbrei, Salzstangen und Suppen
                                (Kartoffelsuppe, Möhrensuppe) erhalten. Falls
                                Erbrechen ausbleibt, kann zunehmend auf
                                Normalkost umgestellt werden. Sehr süße Getränke
                                sollten nicht getrunken oder mit Wasser verdünnt
                                werden.
                              </p>
                              <h3>Wann muss ich einen Arzt aufsuchen?</h3>
                              <p>
                                Die sorgfältige Beobachtung Ihres Kindes ist
                                besonders wichtig. Bei jeglichen
                                Verhaltensänderungen Ihres Kindes sollten Sie
                                umgehend einen Arzt kontaktieren. Achten Sie
                                auch darauf, ob die Urinmenge verringert ist
                                oder ob er eine ungewöhnliche Dunkelfärbung
                                ausweist. Dies können beides Anzeichen einer
                                beginnenden Austrocknung sein. Bei Fieber,
                                stärkerem Erbrechen (mehr als 4-mal innerhalb
                                von 24 Stunden), Blut oder Schleim im Stuhl
                                sollten Sie ebenfalls sofort einen Arzt
                                aufsuchen. Der Arzt entscheidet dann im
                                Einzelfall über das weitere Vorgehen. Er kann
                                unter Umständen auch eine medikamentöse
                                Behandlung verordnen.
                              </p>
                              <p>&nbsp;</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="std-site">
                        <div className="leftcol group-left"></div>
                        <div className="rightcol group-right"></div>
                      </div>
                      <div className="group-footer"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="region region-special-block">
                <div id="block-block-8" className="block block-block">
                  <div className="content">
                    <h3>Welches IMODIUM® ist das Richtige für mich?</h3>
                    <p>
                      <img
                        alt
                        src="/sites/default/files/Imodium_Range_2_Produkte_699x275.jpg"
                        style={{
                          width: "699px",
                          height: "274px"
                        }}
                      />
                    </p>
                    <div className="nextbtn">
                      <a className="btn" href="/imodium-produkte/">
                        <span className="start">Produktübersicht</span>
                        <span className="end">&nbsp;</span>
                      </a>
                    </div>
                    <div className="clear">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/richtig-essen/" title>
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf active-trail">
                    <a
                      href="/durchfall-bei-kindern/"
                      title
                      className="active-trail active"
                    >
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbpk52"
        />
      </div>
    );
  }
}

export default Page;
